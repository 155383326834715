export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการหวย',
        route: '/manage-lotto',
        fontIcon: 'fas fa-dice',
        items: [
          {
            name: 'รายการหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/market'
          },
          {
            name: 'จัดกลุ่มหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/group'
          },
          {
            name: 'อัตราจ่ายทั้งหมด',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/pay-rate'
          },
          {
            name: 'เปิด/ปิด อัตราจ่าย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/off-pay-rate'
          },
          {
            name: 'อัตราจ่ายหวยชุด',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/lottoset-rate'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการเกม',
        route: '/manage-game',
        fontIcon: 'fas fa-gamepad',
        items: [{
          name: 'หมวดหมู่เกม',
          fontIcon: 'fas fa-angle-double-right',
          to: '/manage-game/category'
        }, {
          name: 'ค่ายเกม',
          fontIcon: 'fas fa-angle-double-right',
          to: '/manage-game/creator'
        }, {
          name: 'รายชื่อเกม',
          fontIcon: 'fas fa-angle-double-right',
          to: '/manage-game/list'
        }]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการสมาชิก',
        route: '/manage-account',
        fontIcon: 'fas fa-user-plus',
        items: [
          {
            name: 'ระดับเอเย่นต์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/level'
          },
          {
            name: 'บัญชีบริษัท',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/company'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่าสมาชิก',
        route: '/user-setting',
        fontIcon: 'fas fa-user-cog',
        items: [
          {
            name: 'เปิด/ปิด กลุ่มหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/lotto-group'
          },
          {
            name: 'เปิด/ปิด รายการหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/lotto-market'
          },
          {
            name: 'เปิด/ปิด/ตั้งค่า อัตราจ่าย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/payrates'
          },
          {
            name: 'ตั้งค่าอัตราจ่ายหวยชุด',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/lottoset'
          },
          {
            name: 'ตั้งค่าเกม - เปิด/ปิด',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/game-available'
          },
          {
            name: 'ตั้งค่าเกม - แบ่งหุ้น/คอมฯ',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/game-holder'
          }
        ]
      }
    ]
  }
]
